import { ChoiceOption, QuestionModel, QuestionType } from "@ditdot-dev/vue-flow-form";

export const questions = [
  new QuestionModel({
    id: 'faixa_etaria',
    tagline: 'Queremos ajudar mas não sabemos como...',
    title: 'Olá 👋, qual sua faixa etária?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Acima de 44 anos',
        value: 'maior_que_44'
      }),
      new ChoiceOption({
        label: 'Entre 35 e 44 anos',
        value: 'entre_35_e_44'
      }),
      new ChoiceOption({
        label: 'Entre 25 e 34 anos',
        value: 'entre_25_e_34'
      }),
      new ChoiceOption({
        label: 'Entre 15 e 24 anos',
        value: 'entre_15_e_24'
      }),
      new ChoiceOption({
        label: 'Abaixo de 15 anos',
        value: 'abaixo_de_15'
      })
    ]
  }),
  new QuestionModel({
    id: 'estuda',
    tagline: '...com essa pesquisa esperamos te entender melhor...',
    title: 'Você estuda?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Sim, estou na faculdade/universidade',
        value: 'sim_faculdade'
      }),
      new ChoiceOption({
        label: 'Sim, estou no ensino médio/fundamental',
        value: 'sim_ensino_medio_fundamental'
      }),
      new ChoiceOption({
        label: 'Não, quero seguir estudando por conta própria',
        value: 'nao_conta_propria'
      }),
      new ChoiceOption({
        label: 'Não, já concluí',
        value: 'nao_terminei'
      })
    ]
  }),
  new QuestionModel({
    id: 'capacitacao',
    tagline: '...te convido a crescer junto comigo...',
    title: 'Você participa de alguma capacitação?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Sim',
        value: 'sim'
      }),
      new ChoiceOption({
        label: 'Não, já fiz vários cursos mas agora quero colocar em prática',
        value: 'nao_quero_pratica'
      }),
      new ChoiceOption({
        label: 'Não, ainda estou procurando um curso bom',
        value: 'nao_procurando'
      }),
      new ChoiceOption({
        label: 'Não, ainda nem sei qual curso fazer',
        value: 'nao_nem_sei_qual_curso'
      }),
      new ChoiceOption({
        label: 'Não',
        value: 'nao'
      })
    ]
  }),
  new QuestionModel({
    id: 'algo_novo',
    tagline: '...não iremos mudar o mundo...',
    title: 'Quando surge algo novo, o que você faz?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Procuro por artigos no Google',
        value: 'procuro_artigo_google'
      }),
      new ChoiceOption({
        label: 'Procuro por cursos no Google',
        value: 'procuro_cursos_google'
      }),
      new ChoiceOption({
        label: 'Procuro por vídeos no Youtube',
        value: 'procuro_videos_youtube'
      }),
      new ChoiceOption({
        label: 'Procuro pelo tema em minha plataforma de cursos',
        value: 'procuro_plataforma_cursos'
      }),
      new ChoiceOption({
        label: 'Pergunto em grupos de tecnologia',
        value: 'pergunto_grupos_tecnologia'
      })
    ]
  }),
  new QuestionModel({
    id: 'rede_social',
    tagline: '...queremos melhorar o fluxo...',
    title: 'Qual rede social você mais usa?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    allowOther: true,
    options: [
      new ChoiceOption({
        label: 'Twitter',
        value: 'twitter'
      }),
      new ChoiceOption({
        label: 'LinkedIn',
        value: 'linkedin'
      }),
      new ChoiceOption({
        label: 'Telegram',
        value: 'telegram'
      }),
      new ChoiceOption({
        label: 'Instagram',
        value: 'instagram'
      })
    ]
  }),
  new QuestionModel({
    id: 'conteudo_ti',
    tagline: '...vamos te direcionar os melhores conteúdos...',
    title: 'Sobre conteúdos de TI',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Existem tantos conteúdos na internet que não sei por onde começar',
        value: 'muitos_conteudos_nao_sei_como_comecar'
      }),
      new ChoiceOption({
        label: 'Sempre pesquiso mas dificilmente encontro o que quero',
        value: 'sempre_pesquiso_dificil_encontrar'
      }),
      new ChoiceOption({
        label: 'Encontro o que quero mas geralmente num idioma que não domino',
        value: 'encontro_em_outro_idioma'
      }),
      new ChoiceOption({
        label: 'Tem muitos conteúdos que estão distantes do mercado de trabalho',
        value: 'muitos_conteudos_distantes_do_mercado'
      }),
      new ChoiceOption({
        label: 'Sempre encontro o que quero e consigo utilizar os conteúdos para crescer na carreira',
        value: 'encontro_consigo_utilizar_para_crescer'
      })
    ]
  }),
  new QuestionModel({
    id: 'trabalha',
    tagline: '...praticar seguindo o dia-a-dia das empresas...',
    title: 'Você está trabalhando?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    jump: {
      nao: 'trabalhou',
      _other: 'area_ti'
    },
    options: [
      new ChoiceOption({
        label: 'Sim, recebo periodicamente meu salário',
        value: 'sim_recebo_periodicamente'
      }),
      new ChoiceOption({
        label: 'Sim, trabalho como freelancer',
        value: 'sim_freelancer'
      }),
      new ChoiceOption({
        label: 'Sim, estou num emprego temporário',
        value: 'sim_emprego_temporario'
      }),
      new ChoiceOption({
        label: 'Sim, estou estagiando',
        value: 'sim_estagiando'
      }),
      new ChoiceOption({
        label: 'Não',
        value: 'nao'
      })
    ]
  }),
  new QuestionModel({
    id: 'area_ti',
    tagline: '...porque complicar, se existe internet?...',
    title: 'Você trabalha na área de TI?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    jump: {
      sim: 'mudar_funcao',
      nao: 'migrar_area'
    },
    options: [
      new ChoiceOption({
        label: 'Sim',
        value: 'sim'
      }),
      new ChoiceOption({
        label: 'Não',
        value: 'nao'
      })
    ]
  }),
  new QuestionModel({
    id: 'mudar_funcao',
    tagline: '...nós temos experiência no mercado de TI...',
    title: 'Você pretende mudar de função?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    jump: {
      _other: 'email'
    },
    options: [
      new ChoiceOption({
        label: 'Sim',
        value: 'sim'
      }),
      new ChoiceOption({
        label: 'Não, quero seguir carreira',
        value: 'nao_quero_carreira'
      })
    ]
  }),
  new QuestionModel({
    id: 'migrar_area',
    tagline: '...tecnologia deve ajudar pessoas...',
    title: 'Você pretende trabalhar na área de TI?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    jump: {
      _other: 'email'
    },
    options: [
      new ChoiceOption({
        label: 'Sim, já estou me preparando para isso',
        value: 'sim_ja_me_preparando'
      }),
      new ChoiceOption({
        label: 'Sim, estou organizando meu tempo para começar a me preparar',
        value: 'sim_organizando_tempo'
      }),
      new ChoiceOption({
        label: 'Sim, só não sei por onde começar para conseguir',
        value: 'sim_nao_sei_como_comecar'
      }),
      new ChoiceOption({
        label: 'Não, gosto de tecnologia mas não é o que quero profissionalmente',
        value: 'nao_quero_ti_profissionalmente'
      })
    ]
  }),
  new QuestionModel({
    id: 'trabalhou',
    tagline: '...porque complicar, se existe internet?...',
    title: 'Você já trabalhou?',
    type: QuestionType.MultipleChoice,
    nextStepOnAnswer: true,
    required: true,
    options: [
      new ChoiceOption({
        label: 'Sim, trabalhava com TI e quero voltar',
        value: 'sim_trabalhava_ti_e_quero_voltar'
      }),
      new ChoiceOption({
        label: 'Sim, trabalhava em outra área mas agora busco vagas de TI',
        value: 'sim_trabalhava_outra_area_busco_vagas'
      }),
      new ChoiceOption({
        label: 'Sim, estou disposto a trabalhar em qualquer área',
        value: 'sim_trabalhar_qualquer_area'
      }),
      new ChoiceOption({
        label: 'Não, procuro meu primeiro emprego',
        value: 'nao_primeiro_emprego'
      })
    ]
  }),
  new QuestionModel({
    id: 'email',
    tagline: '...você pode pular mas prometo enviar só o necessário.',
    title: '😎 Deseja ser informado? Insira seu e-mail',
    subtitle: 'Estamos preparando um ambiente para auxiliar no seu processo de qualificação e contratação',
    type: QuestionType.Email
  })
]