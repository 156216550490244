<template>
  <div>
    <header class="vff-header">
      <div class="f-container">
        <Logo />
        <address>
          hello@achance.digital
        </address>
      </div>
    </header>
    <slot></slot>
  </div>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "ContainerPurple",
  components: {
    Logo
  }
}
</script>

<style scoped>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css';

.vff-header .f-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vff-header .f-container address {
  font-style: normal;
  color: white;
}
</style>