import { CloudEvent, HTTP } from "cloudevents";
import { SNS } from "aws-sdk";

export const sendEvent = (session, data, subject='') => {
  const type = process.env.VUE_APP_EVENT_TYPE
  const source = window.location.href

  const ce = new CloudEvent({session, type, source, subject, data});
  const message = HTTP.structured(ce);

  if (process.env.VUE_APP_DEBUG !== 'false') {
    console.log(message.body)
  } else {
    const publishPromise = new SNS({
      apiVersion: process.env.VUE_APP_SNS_API_VERSION,
      accessKeyId: process.env.VUE_APP_SNS_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_SNS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_SNS_REGION,
    }).publish({
      Message: String(message.body),
      TopicArn: process.env.VUE_APP_SNS_TOPIC_ARN
    }).promise();

    publishPromise.catch(
      function (err) {
        console.log(err)
      }
    )
  }
}