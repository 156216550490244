import { LanguageModel } from "@ditdot-dev/vue-flow-form";

export const language = new LanguageModel({
  continue: 'Continuar',
  skip: 'Pular',
  pressEnter: 'Pressione :enterKey',
  multipleChoiceHelpText: 'Escolha quantas opções você quiser',
  multipleChoiceHelpTextSingle: 'Escolha apenas uma opção',
  otherPrompt: 'Digite aqui outra opção',
  placeholder: 'Escreva sua resposta aqui...',
  submitText: 'Enviar',
  longTextHelpText: ':shiftKey + :enterKey para inserir uma quebra de linha.',
  prev: 'Anterior',
  next: 'Próximo',
  percentCompleted: ':percent% completado',
  invalidPrompt: 'Por favor, preencha esse campo corretamente',
  thankYouText: 'Obrigado! Clique no botão enviar',
  successText: 'Informações enviadas com sucesso.',
  ariaOk: 'Pressione para continuar',
  ariaRequired: 'Este passo é obrigatório',
  ariaPrev: 'Passo anterior',
  ariaNext: 'Próximo passo',
  ariaSubmitText: 'Pressione para enviar',
  ariaMultipleChoice: 'Pressione :letter para selecionar',
  ariaTypeAnswer: 'Escreva sua resposta aqui'
})