<template>
  <svg id="logo" data-name="logo" xmlns="http://www.w3.org/2000/svg" width="125" height="28"
       viewBox="0 0 125 28" preserveAspectRatio="xMidYMid meet">
    <path class="cls-1"
          d="M37.7277,19.3758a4.9433,4.9433,0,0,1-1.6785-2.14A7.6558,7.6558,0,0,1,35.4714,14.2a7.796,7.796,0,0,1,.5778-3.0679,4.8733,4.8733,0,0,1,1.7-2.1509,4.572,4.572,0,0,1,2.6922-.786,3.9868,3.9868,0,0,1,2.5179.7534,5.1543,5.1543,0,0,1,1.493,1.8233h.1528V8.5449H46.11V20.0307H44.6054V17.5413h-.1528a5.38,5.38,0,0,1-1.5584,1.867,4.0833,4.0833,0,0,1-2.54.7534A4.402,4.402,0,0,1,37.7277,19.3758ZM43.57,17.5087a5.479,5.479,0,0,0,.97-3.33,5.4311,5.4311,0,0,0-.97-3.3192,3.273,3.273,0,0,0-2.8013-1.31A3.2271,3.2271,0,0,0,37.9675,10.87a5.53,5.53,0,0,0-.9482,3.3083,5.52,5.52,0,0,0,.959,3.33,3.6189,3.6189,0,0,0,5.5916,0Z"/>
    <path class="cls-1"
          d="M51.352,19.3319a5.1459,5.1459,0,0,1-1.7985-2.2272,7.3608,7.3608,0,0,1-.5884-2.9261,7.2311,7.2311,0,0,1,.5884-2.8934A5.2349,5.2349,0,0,1,51.352,9.0579a5.031,5.031,0,0,1,2.9974-.8624,5.0985,5.0985,0,0,1,2.5723.6115,4.4244,4.4244,0,0,1,1.6568,1.6158,5.7183,5.7183,0,0,1,.7413,2.1618H57.8591a3.7806,3.7806,0,0,0-1.1118-2.2056,3.7842,3.7842,0,0,0-5.2644.47,5.3913,5.3913,0,0,0-.992,3.33,5.4277,5.4277,0,0,0,.992,3.3518,3.8005,3.8005,0,0,0,5.2644.48,3.7446,3.7446,0,0,0,1.1118-2.2164H59.32a5.7135,5.7135,0,0,1-.7413,2.1618,4.4174,4.4174,0,0,1-1.6568,1.6157,5.708,5.708,0,0,1-5.57-.24Z"/>
    <path class="cls-1"
          d="M62.088,3.6971h1.526v7.01h.1525a4.3115,4.3115,0,0,1,1.4716-1.78,4.1167,4.1167,0,0,1,2.4742-.7313,4.2643,4.2643,0,0,1,2.4414.6766,4.167,4.167,0,0,1,1.5044,1.8237,6.23,6.23,0,0,1,.5012,2.522v6.8129H70.6769v-6.66a3.733,3.733,0,0,0-.9809-2.8058,3.5126,3.5126,0,0,0-2.55-.95,3.6468,3.6468,0,0,0-1.7658.437A3.2225,3.2225,0,0,0,64.0936,11.34a4.0749,4.0749,0,0,0-.48,2.0307v6.66H62.088Z"/>
    <path class="cls-1"
          d="M77.2058,19.3758a4.9438,4.9438,0,0,1-1.6784-2.14A7.6558,7.6558,0,0,1,74.95,14.2a7.796,7.796,0,0,1,.5778-3.0679,4.8733,4.8733,0,0,1,1.7-2.1509,4.5719,4.5719,0,0,1,2.6921-.786,3.9869,3.9869,0,0,1,2.518.7534,5.1543,5.1543,0,0,1,1.493,1.8233h.1525V8.5449h1.5044V20.0307H84.0833V17.5413h-.1525a5.3844,5.3844,0,0,1-1.5584,1.867,4.0833,4.0833,0,0,1-2.54.7534A4.4025,4.4025,0,0,1,77.2058,19.3758Zm5.842-1.8671a5.4784,5.4784,0,0,0,.97-3.33,5.4305,5.4305,0,0,0-.97-3.3192,3.2721,3.2721,0,0,0-2.801-1.31A3.2271,3.2271,0,0,0,77.4457,10.87a5.53,5.53,0,0,0-.9482,3.3083,5.52,5.52,0,0,0,.959,3.33,3.6186,3.6186,0,0,0,5.5913,0Z"/>
    <path class="cls-1"
          d="M89.1188,8.5449h1.526v2.1617h.1525a4.31,4.31,0,0,1,1.4714-1.78,4.1173,4.1173,0,0,1,2.4744-.7313,4.2643,4.2643,0,0,1,2.4414.6766,4.169,4.169,0,0,1,1.5044,1.8237,6.23,6.23,0,0,1,.5012,2.522v6.8129H97.7075v-6.66a3.74,3.74,0,0,0-.9809-2.7949,3.7565,3.7565,0,0,0-5.1009.0764,3.7959,3.7959,0,0,0-.9809,2.7185v6.66h-1.526Z"/>
    <path class="cls-1"
          d="M104.4544,19.3319a5.143,5.143,0,0,1-1.7985-2.2272,7.3556,7.3556,0,0,1-.5884-2.9261,7.2259,7.2259,0,0,1,.5884-2.8934,5.232,5.232,0,0,1,1.7985-2.2273,5.03,5.03,0,0,1,2.9973-.8624,5.0993,5.0993,0,0,1,2.5723.6115,4.4254,4.4254,0,0,1,1.6569,1.6158,5.7039,5.7039,0,0,1,.7409,2.1618h-1.46a3.7816,3.7816,0,0,0-1.1122-2.2056,3.3316,3.3316,0,0,0-2.3977-.8515,3.3659,3.3659,0,0,0-2.8665,1.3211,5.39,5.39,0,0,0-.9922,3.33,5.4265,5.4265,0,0,0,.9922,3.3518,3.8,3.8,0,0,0,5.2642.48,3.7456,3.7456,0,0,0,1.1122-2.2164h1.46a5.6992,5.6992,0,0,1-.7409,2.1618,4.4185,4.4185,0,0,1-1.6569,1.6157,5.7078,5.7078,0,0,1-5.57-.24Z"/>
    <path class="cls-1"
          d="M117.0213,19.31a5.1721,5.1721,0,0,1-1.7872-2.2272,7.4575,7.4575,0,0,1,0-5.8087,5.17,5.17,0,0,1,1.7872-2.2273,4.953,4.953,0,0,1,2.9432-.8515,4.65,4.65,0,0,1,3.662,1.55A6.5432,6.5432,0,0,1,125,14.2225v.4149h-8.85V14.79a4.4526,4.4526,0,0,0,.4688,1.9977,3.7572,3.7572,0,0,0,1.3405,1.5177,3.5512,3.5512,0,0,0,1.9835.5677,3.5086,3.5086,0,0,0,2.0932-.6224,3.7731,3.7731,0,0,0,1.3076-1.6921h1.4826a5.3642,5.3642,0,0,1-1.7006,2.5766,4.744,4.744,0,0,1-3.1607,1.0264A4.9523,4.9523,0,0,1,117.0213,19.31Zm6.4748-5.7865a4.5709,4.5709,0,0,0-1.0463-3.1226,3.7285,3.7285,0,0,0-5.1776.1745,4.6729,4.6729,0,0,0-1.0788,2.9481Z"/>
    <path class="cls-1"
          d="M30.8229,26.992H19.9413L11.12,11.1988l-8.231,14.46H16.0713V26.992H.5976L15.7778.3245ZM20.7216,25.6588h7.82L15.7726,3.0249,11.8894,9.8465Z"/>
    <circle class="cls-2" cx="15.7667" cy="26.1651" r="1.8318"/>
    <circle class="cls-2" cx="1.8318" cy="26.1651" r="1.8318"/>
    <circle class="cls-2" cx="20.8952" cy="26.1651" r="1.8318"/>
    <circle class="cls-2" cx="29.5935" cy="26.1651" r="1.8318"/>
    <circle class="cls-2" cx="15.8057" cy="1.8349" r="1.8318"/>
    <circle class="cls-2" cx="11.4196" cy="9.9706" r="1.8318"/>
  </svg>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}

.cls-2 {
  fill: #f14e5b;
}
</style>