<template>
  <div id="app">
    <ContainerPurple>
      <People />
    </ContainerPurple>
  </div>
</template>

<script>
import dotenv from 'dotenv';
import ContainerPurple from "@/components/containers/ContainerPurple";
import People from "@/components/surveys/People";

export default {
  name: 'App',
  components: {
    People,
    ContainerPurple
  },
  created() {
    dotenv.config()
  }
}
</script>