<template>
  <div>
    <flow-form
        ref="flowform"
        v-on:complete="onComplete"
        v-on:answer="onAnswer"
        v-bind:questions="questions"
        v-bind:language="language">

      <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
      <!-- We've overriden the default "complete" slot content -->
      <template v-slot:complete>
        <div class="f-section-wrap">
          <span class="f-tagline">Pessoas &gt; Tecnologia</span>
          <span class="fh2">Ufa, acabou! Nos vemos em breve 😉</span>
          <p class="f-description"><span>Adoraria receber um e-mail seu, pode mandar!</span></p>
        </div>
      </template>

      <!-- We've overriden the default "completeButton" slot content -->
      <template v-slot:completeButton>
        <div class="f-submit">
          <!-- Leave empty to hide default submit button -->
        </div>
      </template>

    </flow-form>
  </div>
</template>

<script>
import FlowForm from '@ditdot-dev/vue-flow-form';
import { v4 as uuidv4 } from "uuid";
import { sendEvent } from "@/cloud/aws/sns";
import { questions as peopleQuestions } from '@/questions/people'
import { language as languagePtBr } from "@/languages/ptBr";

export default {
  name: "People",
  components: {
    FlowForm
  },
  data() {
    return {
      session: null,
      submitted: false,
      completed: false,
      language: languagePtBr,
      questions: peopleQuestions
    }
  },
  created() {
    if (!localStorage.getItem('session')) {
      localStorage.setItem('session', uuidv4())
    }
    this.session = localStorage.getItem('session')
  },
  methods: {
    onAnswer: function (questionAnswered) {
      const data = {
        questionTitle: questionAnswered.question.title,
        answer: questionAnswered.answer
      }
      sendEvent(this.session, data, 'people_survey')
    },
    /* eslint-disable-next-line no-unused-vars */
    onComplete: function (completed, questionList) {
      this.completed = completed
      this.$refs.flowform.submitted = true
    }
  }
}
</script>

<style scoped>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
</style>